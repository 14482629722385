<template>
  <div>
    <!-- 表单 -->
    <el-form :model="form">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="请选择类型">
            <el-select v-model="form.type" placeholder="请选择类型">
              <el-option label="校园风采" value="0" />
              <el-option label="招生报名" value="1" />
              <el-option label="国家政策" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="请输入分类名称 ">
            <el-input v-model="form.name" />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item>
            <el-button type="primary" @click="onSearchBtn" :icon="Search"
              >搜索</el-button
            >
          </el-form-item>
        </el-col>

        <el-col :span="2">
          <el-form-item>
            <el-button :icon="CirclePlus" @click="onAddBtn"> 添加</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->
    <el-table
      :header-cell-style="{ background: '#f2f2f2' }"
      border
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column prop="name" label="分类名称" />
      <el-table-column label="所属分类">
        <template #default="{ row }">
          {{ businessProgress(row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序值" />
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="删除分类后，该分类下的文章也将被删除?"
            @confirm="onDeleteBtn(row.id)"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 添加编辑 -->
    <addClass
      v-model="isVisible"
      :onTableData="onTableData"
      :editData="editData"
    ></addClass>
  </div>
</template>

<script setup>
import { Search, CirclePlus } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { categoryListAPI, deleteClassificationAPI } from '@/api/classification'
import addClass from './components/addClass.vue'
import { ElMessage } from 'element-plus'
/**
 *  表单 S
 */

// 搜索数据源
const form = ref({
  page: 1,
  limit: 10,
  total: 0,
  name: '',
  type: ''
})
// 搜素
const onSearchBtn = () => {
  onTableData()
}

/**
 *  表单 E
 */

/**
 *  表格 S
 */

// 表格数据
const onTableData = async () => {
  const { data, total } = await categoryListAPI(form.value)
  form.value.total = total
  tableData.value = data
}
onTableData()

// 状态值修改
const businessProgress = (num) => {
  const map = {
    0: '校园风采',
    1: '招生报名',
    2: '国家政策'
  }
  return map[num]
}
// 编辑

const editData = ref({})

const onAddBtn = () => {
  editData.value = {}
  isVisible.value = true
}

const onEditBtn = (row) => {
  editData.value = row
  isVisible.value = true
}
// 删除
const onDeleteBtn = async (id) => {
  await deleteClassificationAPI({ id })
  ElMessage.success('删除分类成功')
  onTableData()
}
// 数据源
const tableData = ref([])
/**
 *  表格 E
 */

/**
 *  分页
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  form.value.limit = val
  onTableData()
}

// 页码值
const handleCurrentChange = (val) => {
  form.value.page = val
  onTableData()
}

/**
 *  分页 E
 */

/*
 * 添加 / 编辑  S
 */
const isVisible = ref(false)
</script>

<style lang="scss" scoped></style>
