<template>
  <!-- 添加 编辑    -->
  <el-dialog :title="title" width="30%" v-model="dialogShow">
    <el-form :model="form" label-width="80px">
      <el-form-item label="所属栏目">
        <el-select
          v-model="form.type"
          placeholder="所属栏目"
          style="width: 100%"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
          <!-- <el-option label="招生报名" value="2" />
          <el-option label="国家政策" value="3" /> -->
        </el-select>
      </el-form-item>
      <el-form-item label="分类名称" placeholder="分类名称">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="排序值">
        <el-input-number
          :min="1"
          v-model="form.sort"
          style="width: 100%"
          controls-position="right"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogShow = false"> 取消</el-button>
        <el-button type="primary" @click="addClassBtn">添加</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import {
  addCategoryAPI,
  modifyTheClassificationAPI
} from '@/api/classification'
import { ElMessage } from 'element-plus'
const props = defineProps({
  // 接收 v-model
  modelValue: {
    type: Boolean,
    required: true
  },
  editData: {
    type: Object
  },
  onTableData: {
    type: Function
  }
})

defineEmits(['update:modelValue']) // 注册 v-model 事件
const dialogShow = useVModel(props) // 得到一个响应式的数据，可以直接修改

// 标题
const title = ref('新增分类')
// 添加
const addClassBtn = async () => {
  if (form.value.id) {
    await modifyTheClassificationAPI(form.value)
    ElMessage.success('编辑分类成功。')
  } else {
    await addCategoryAPI(form.value)
    ElMessage.success('添加分类成功。')
  }

  dialogShow.value = false
  props.onTableData()
}

const typeList = [
  { label: '校园风采', value: 0 },
  { label: '招生报名', value: 1 },
  { label: '国家政策', value: 2 }
]

// 监听数据
watch(
  () => props.editData,
  (val) => {
    title.value = val.id ? '编辑分类' : '新增分类'
    form.value.id = val.id
    form.value.name = val.name
    form.value.type = val.type
    form.value.sort = val.sort
  }
)

const form = ref({
  name: '',
  type: '',
  sort: 1
})
</script>

<style lang="scss" scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
