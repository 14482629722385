import request from '@/utils/request'

// 分类列表
export const categoryListAPI = (params) => {
  return request({
    url: '/classList',
    method: 'GET',
    params
  })
}

// 添加分类
export const addCategoryAPI = (data) => {
  return request({
    url: '/addClass',
    method: 'POST',
    data
  })
}

// 修改分类
export const modifyTheClassificationAPI = (data) => {
  return request({
    url: '/classSave',
    method: 'POST',
    data
  })
}

// 删除
export const deleteClassificationAPI = (params) => {
  return request({
    url: '/classDel',
    method: 'GET',
    params
  })
}
